import React from "react"
import SingleLayout from '../components/layouts/SingleLayout_10'
import Seo from "../components/seo"

const About = () => {
  const hero = {
    title:"About GyanBlog", 
    image: "/images/about.jpg"
  }
  return (
    <>
      <SingleLayout hero={hero}>
        <Seo 
          title="About GyanBlog" 
          description="Explore what kind of programming and SEO posts are there. Read about our Coding Interview Guide" 
          image="/images/about.jpg"
          />
        <div className="w-lg-60 mx-lg-auto">
          <p>GyanBlog is a programming blog which talks about practical technical stuff that we used to face in our day to day job. It has various stuff from a programmer perspective, from a startup perspective.</p>

          <p>GyanBlog started with the concept of providing practical and quality information. Gyanblog is a collection of real life problems and their solutions. Gyanblog also covers best practices around Software design and architecture. We also covered cloud architecture and microservices.</p>

          <p>I will also share lot of startup ideas that I'm collecting over the years, but never got time to execute them. Its very important to create a source of passive income. You never know when that passive income become your primary income.</p>

          <h2>GyanBlog Sections</h2>
<div class="row">
	<div class="col-6">
		<ul class="list-group">
			<li class="list-group-item">Tutorials</li>
      <li class="list-group-item">Scalability</li>
			<li class="list-group-item">Coding best practices</li>
			<li class="list-group-item">Software Architecture</li>
			<li class="list-group-item">Design Patterns</li>
			<li class="list-group-item">Coding Interviews</li>
			<li class="list-group-item">Website development</li>
			<li class="list-group-item">Website Hosting</li>
			<li class="list-group-item">Stel by Step Solution to coding or designing problems</li>
			<li class="list-group-item">SEO</li>
			<li class="list-group-item">Digital Marketing</li>
			<li class="list-group-item">Startup and Entrepreneurship</li>
			<li class="list-group-item">Affiliate</li>
			<li class="list-group-item">How to improve your technical skills</li>
			<li class="list-group-item">Programming languages references</li>
			<li class="list-group-item">Security Best practices</li>
			<li class="list-group-item">Secure Development</li>
			<li class="list-group-item">Ethical Hacking</li>
		</ul>
	</div>
</div>
<p></p>

<p></p>

<h2>Topics I will be writing about</h2>
<p></p>
<p>
	<h4>Programming Languages/Scripting</h4>
	<table class="table">
		<tbody>
		  <tr>
			<td>Java</td>
			<td>Node.js</td>
			<td>Python</td>
		  </tr>
		  <tr>
			<td>Javascript</td>
			<td>C/C++</td>
			<td>Shell Script</td>
		  </tr>
		  <tr>
			<td>Php</td>
			<td></td>
			<td></td>
		  </tr>
		</tbody>
	</table>

	<h4>Architectural Skills</h4>
	<table class="table">
		<tbody>
			<tr>
				<td>Software Architecture</td>
				<td>Software Design</td>
				<td>Micro Service</td>
			  </tr>
			  <tr>
				<td>Cloud Architecture</td>
				<td>Docker</td>
				<td>Event Driven architecture</td>
			</tr>
			<tr>
				<td>Serverless Architecture</td>
				<td></td>
				<td></td>
			</tr>
		</tbody>
	</table>

	<h4>Frameworks/Libraries</h4>
	<table class="table">
		<tbody>
			<tr>
				<td>Bootstrap</td>
				<td>Spring Security</td>
				<td>Spring LDAP</td>
			</tr>
			<tr>
				<td>Spring Framework</td>
				<td>Spring MVC</td>
				<td>Spring Data</td>
			  </tr>
			  <tr>
				<td>Hibernate</td>
				<td>React.js</td>
				<td>Drupal</td>
			  </tr>
		</tbody>
	</table>

	<h4>Database</h4>
	<table class="table">
		<tbody>
			<tr>
				<td>Elastic Search</td>
				<td>MongoDB</td>
				<td>Oracle</td>
			</tr>
			<tr>
				<td>Mysql</td>
				<td>Sqlite</td>
				<td></td>
			</tr>
		</tbody>
	</table>

	<h4>Misc</h4>
	<table class="table">
		<tbody>
		  <tr>
			<td>Web Services</td>
			<td>Web Apps</td>
			<td>Responsive Html</td>
		  </tr>
		  <tr>
			  <td>REST APIs</td>
			  <td>Jenkins</td>
			  <td>CICD</td>
		  </tr>
		  <tr>
			<td>AMQP</td>
			<td>JMS</td>
			<td>SQS/SNS</td>
		  </tr>
		  <tr>
			<td>Rabbit MQ</td>
			<td>Kafka</td>
			<td>Apache Active MQ</td>
		  </tr>
		  <tr>
			<td>Amazon Web Services (AWS)</td>
			<td>Google Cloud</td>
			<td>https/SSL certificate</td>
		  </tr>
		  <tr>
			<td>LAMP Stack</td>
			<td>MERN Stack</td>
			<td>Apache</td>
		  </tr>
		  <tr>
			<td>Nginx</td>
			<td>Jboss</td>
			<td>Linux</td>
		  </tr>
		  <tr>
			<td>Hosting</td>
			<td>Google Best practices</td>
			<td>Web server fine tuning</td>
		  </tr>
		  <tr>
			<td>Optimizing your website</td>
			<td>Optimizing Images</td>
			<td>Monitoring</td>
		  </tr>
		  <tr>
			<td>Splunk</td>
			<td>Newrelic</td>
			<td>Npm</td>
		  </tr>
		  <tr>
			<td>Backend Development</td>
			<td>UI development</td>
			<td>Secure Development</td>
		  </tr>
		  <tr>
			<td>OWASP Security best practices</td>
			<td>Secure Softwaare Engineering</td>
			<td>3rd party library vulnerability</td>
		  </tr>
		  <tr>
			<td>Hacking Stuff</td>
			<td>Ethical Hacking</td>
			<td>Whitebox/Blackbox hacking</td>
		  </tr>
		  <tr>
			<td>Penetration Testing</td>
			<td>Burp</td>
			<td>Maven</td>
		  </tr>
		  <tr>
			<td>Github</td>
			<td>Perforce</td>
			<td>Youtube APIs</td>
		  </tr>
		  <tr>
			<td>SEO/SEM</td>
			<td>CDN</td>
			<td>Visual Studio Code</td>
		  </tr>
		  <tr>
			<td>Eclipse</td>
			<td>InteliJ idea</td>
			<td>SSL, OpenSSL</td>
		  </tr>
		  <tr>
			<td>MAC OSX</td>
			<td>brew</td>
			<td></td>
		  </tr>
		  <tr>
			<td>EC2/S3/ELB/SNS/SQS</td>
			<td>Cloud Front/Lambda</td>
			<td>SES/Cloudwatch/Glacier/RDS/IAM</td>
		  </tr>
		  <tr>
			<td>Paypal</td>
			<td>Instamojo payment gateway</td>
			<td>Get work done by freelancers</td>
		  </tr>
		  <tr>
			<td>Cloudflare</td>
			<td>Keyword Research</td>
			<td>Payment Gateway</td>
		  </tr>
		</tbody>
	  </table>
</p>

        </div>
      </SingleLayout>
    </>
  )
}

export default About